import { QueryResult } from '@apollo/client';
import { Maybe } from '@hotelplan/graphql.types';

export type StopOverDurationType = { minSelected: string; maxSelected: string };

export type RadiusFilter = {
  center: {
    latitude: number;
    longitude: number;
  };
  label?: string;
  radius: number;
};

export interface IFiltersFormState {
  /** Selected popular filter ids */
  popularFilters?: Maybe<Array<string>>;
  /** Selected room type ids */
  roomTypes?: Maybe<Array<string>>;
  accommodationSize?: Maybe<Array<string>>;
  /** Selected board type ids */
  boardTypes?: Maybe<Array<string>>;

  /** Selected main product feature ids */
  mainFeatures?: Maybe<Array<string>>;
  childrenFeatures?: Maybe<Array<string>>;
  beachFeatures?: Maybe<Array<string>>;
  distanceFeatures?: Maybe<Array<string>>;
  sportFeatures?: Maybe<Array<string>>;
  wellnessFeatures?: Maybe<Array<string>>;
  hotelFeatures?: Maybe<Array<string>>;
  environmentFeatures?: Maybe<Array<string>>;
  cultureFeatures?: Maybe<Array<string>>;
  refundableFeatures?: Maybe<Array<string>>;

  /** Selected minimal TripAdvisor rating id */
  tripAdvisorRating?: Maybe<string>; // taRating
  /** Selected minimal Hotelplan rating id */
  hotelplanRating?: Maybe<string>; // hpRating
  /** Selected max price per person */
  maxPricePerPerson?: Maybe<string>; // maxPrice
  /** Selected stop overs id */
  flightStopOver?: Maybe<string>; // stopOvers
  /** Selected maximum flight duration in minutes */
  maxFlightDuration?: Maybe<string>; // flightDuration
  /** Selected departure airport platform ids */
  departureAirports?: Maybe<Array<string>>;

  prevChangedFilter?: TFilterOptionName | null;

  /** Selected flight stopover duration ids */
  flightStopOverDuration?: Maybe<StopOverDurationType>;
  /** Selected direct flight departure time id */
  directFlightDepartureTime?: Maybe<string>;
  /** Selected direct flight arrival time id */
  directFlightArrivalTime?: Maybe<string>;
  /** Selected return flight departure time id */
  returnFlightDepartureTime?: Maybe<string>;
  /** Selected return flight arrival time id */
  returnFlightArrivalTime?: Maybe<string>;
  /** Selected flight airlines ids */
  flightAirlines?: Maybe<Array<string>>;

  // Url filters:
  arrivalAirports?: Maybe<Array<string>>;
  arrivalWeekdays?: Maybe<Array<string>>;
  departureWeekdays?: Maybe<Array<string>>;
  minPrice?: Maybe<string>;
  productCode?: Maybe<string>;
  provider?: Maybe<string>;
  flightProvider?: Maybe<string>;

  // Map filters:
  radius?: RadiusFilter;
}

export type TFilterCountsRecalculationRules = {
  [key in TRecalculatedFilterOptionName]: {
    logic?: 'OR' | 'AND' | null;
    recalculateWithinFilter: boolean;
  };
};

/** Filter option to render */
export interface IFilterOption {
  /** Filter option caption */
  caption: string;
  /** Filter option id */
  id: string;
}

export interface IFilterOptions {
  /** Filter additional product features options */

  mainFeatures: Array<IFilterOption>;
  childrenFeatures: Array<IFilterOption>;
  beachFeatures: Array<IFilterOption>;
  distanceFeatures: Array<IFilterOption>;
  sportFeatures: Array<IFilterOption>;
  wellnessFeatures: Array<IFilterOption>;
  hotelFeatures: Array<IFilterOption>;
  environmentFeatures: Array<IFilterOption>;
  cultureFeatures: Array<IFilterOption>;

  /** Filter popular filter options */
  popularFilters: Array<IFilterOption>;
  /** Filter TripAdvisor rating options */
  tripAdvisorRating: Array<IFilterOption>;
  /** Filter hotelplan rating options */
  hotelplanRating: Array<IFilterOption>;
  /** Amount of stop over options */
  flightStopOver: Array<IFilterOption>;
  /** Filter room type options */
  roomTypes: Array<IFilterOption>;
  accommodationSize: Array<IFilterOption>;
  /** Filter room board options */
  boardTypes: Array<IFilterOption>;
  /** Departure airport options */
  departureAirports: Array<IFilterOption>;
  maxPricePerPerson: IFilterOption; // maxPrice
  /** Flight duration */
  maxFlightDuration: IFilterOption; // flightDuration
  /** Direct flight arrival time options */
  directFlightArrivalTime: Array<IFilterOption>;
  /** Return flight departure time options */
  returnFlightDepartureTime: Array<IFilterOption>;

  // FRL filters:
  /** Flight stopover duration options */
  flightStopOverDuration: Array<IFilterOption>;
  /** Direct flight departure time options */
  directFlightDepartureTime: Array<IFilterOption>;
  /** Return flight arrival time options */
  returnFlightArrivalTime: Array<IFilterOption>;
  /** Flight airlines options */
  flightAirlines: Array<IFilterOption>;

  // Url filters:
  arrivalAirports: Array<IFilterOption>;
  arrivalWeekdays: Array<IFilterOption>;
  departureWeekdays: Array<IFilterOption>;
  minPrice: IFilterOption;
  productCode?: IFilterOption;
  provider?: IFilterOption;
  flightProvider?: IFilterOption;
  refundableFeatures?: Array<IFilterOption>;

  // No options filters:
  radius?: any;
}

export type TFilterOptionName = keyof IFilterOptions;

export enum OptionsIds {
  allInclusive = 'all-inclusive',
  halfBoard = 'half-board',
  sustainability = 'sustainability',
  beachHolidays = 'beach-holidays',
  cityHolidays = 'city-holidays',
  ZRH = 'ZRH',
  GVA = 'GVA',
  ZHR = 'ZHR',
  HP_MIN_4_STARS = 'HP_MIN_4_STARS',
  DIRECT = 'DIRECT',
}

export type TRecalculatedFilterOptionName = Exclude<
  TFilterOptionName,
  | 'arrivalAirports'
  | 'arrivalWeekdays'
  | 'departureWeekdays'
  | 'minPrice'
  | 'productCode'
  | 'provider'
  | 'flightProvider'
  | 'refundableFeatures'
  | 'radius'
>;

export type TFilterOptionOrderName =
  | TFilterOptionName
  | 'ratings'
  | 'flightOptions'
  | 'flightDepartureArrivalTimes';

export type TFilterOptionCaptions = { [key in TFilterOptionOrderName]: string };

export interface IFilterCount {
  count: number;
  id: string;
}

export type TUseCountsOptions = {
  skip: boolean;
  onCompleted?(nextData: QueryResult['data']): any;
  inView?: boolean;
  id?: string;
};

export type TUseCounts<TCounts = unknown> = (
  options?: TUseCountsOptions
) => {
  counts?: TCounts;
  loading: boolean;
  error: boolean;
};

export type TUseMaxPriceCounts = (
  options?: TUseCountsOptions
) => { minPrice?: number; loading: boolean };

export type TUseFlightStopoverDurationCounts = () => {
  maxDuration?: string;
  minDuration?: string;
  loading: boolean;
};

export type TUseFlightAirlinesCounts = (
  options?: TUseCountsOptions
) => {
  loading: boolean;
  options?: { id: string; count: number; caption: string }[];
};

export type TFilterCountsLoader = {
  [key in Exclude<
    TRecalculatedFilterOptionName,
    | 'maxPricePerPerson'
    | 'maxFlightDuration'
    | 'flightStopOverDuration'
    | 'flightAirlines'
    | 'radius'
  >]: TUseCounts<IFilterCount[]>;
} & {
  total: TUseCounts<IFilterCount>;
  maxFlightDuration: TUseCounts<IFilterCount>;
  maxPricePerPerson: TUseMaxPriceCounts;
  flightStopOverDuration: TUseFlightStopoverDurationCounts;
  flightAirlines: TUseFlightAirlinesCounts;
};

export type TFilterCountsStorage = {
  [key in Exclude<
    TFilterOptionName,
    'maxPricePerPerson' | 'maxFlightDuration'
  >]?: IFilterCount;
} & {
  total?: IFilterCount;
  maxFlightDuration?: IFilterCount;
  maxPricePerPerson?: ReturnType<TUseMaxPriceCounts>['minPrice'];
};

export interface IFilterOptionsResult {
  options: IFilterOptions | undefined | null;
  optionCaptions: TFilterOptionCaptions | undefined | null;
  loading: boolean;
}

export type TUseMaxPrice = (
  options?: TUseCountsOptions
) => { loading: boolean; minPrice?: number };

export type TUseFlightStopoverDuration = (
  options?: TUseCountsOptions
) => {
  loading: boolean;
  maxDuration?: string;
  minDuration?: string;
};

export type TUseFlightAirlines = (
  options?: TUseCountsOptions
) => {
  loading: boolean;
  options?: { id: string; count: number; caption: string }[];
};
