import { RefObject } from 'react';
import {
  SrlGroupSorting,
  SrlPrimarySort,
  SrlProductSorting,
} from '@hotelplan/graphql.types';
import { IFiltersFormState } from 'components/domain/filters/Filters.types';
import { ISearchControlFormState } from 'components/domain/searchControl/SearchControl.types';
import { GeoItemFragment } from 'graphql/srl/SRLFragments.generated';

export interface ISRLState {
  searchControl: ISearchControlFormState | null;
  filters: IFiltersFormState | null;
  subGeoFilters: string[] | null;
  primarySort: SrlPrimarySort;
  groupSorting: SrlGroupSorting | undefined;
  productSorting: SrlProductSorting | undefined;
  forceSingleView?: boolean;
}

export interface ISRLGroupItemsList {
  scrollElRef: RefObject<HTMLUListElement>;
  items: GeoItemFragment[];
  loading?: boolean;
  hasMore?: boolean;
  loadMore?: () => void;
}

export enum SRLProductCardVariants {
  ORIGINAL = 'original-design',
  NEW = 'new-design',
  NEW_WITH_PERSON_PRICE = 'new-design-person-price',
}

export enum SRLItemTypes {
  PRODUCT = 'SrlProductItem',
  NUDGE = 'NudgeItem',
}
