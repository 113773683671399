import { mapSearchControlTravelPeriodToFormTravelDates } from '@hotelplan/components.common.travel-dates';
import { mapSearchControlTravelDestinationsToFormTravelDestination } from '@hotelplan/components.common.travel-destination';
import { mapSearchControlTravelRoomsToFormTravelRooms } from '@hotelplan/components.common.travel-rooms';
import {
  SearchControlComponent,
  SrlFilterCriteriaInput,
  SrlSingleItem,
  SrlGeoItem,
  SrlSingleResult,
  SrlGeoGroupResult,
  SrlGeoGroupItem,
  SrlProductSorting,
} from '@hotelplan/graphql.types';
import type { IRunSearchData } from '@hotelplan/libs.tracking';
import {
  SRLGeoMetaData,
  SRLNudgeEventMetaData,
  SRLProductEventMetaData,
  SRLResultsEventMetaData,
} from '@hotelplan/libs.tracking';
import { FilterOptions } from 'components/domain/filters/Filters.constants';
import {
  mapFiltersStateToFilterCriteriaInput,
  mapFiltersStateToTrackableData,
  mapMainFilterComponentValuesToFiltersFormState,
} from 'components/domain/filters/Filters.mappers';
import {
  IFiltersFormState,
  TFilterOptionName,
  OptionsIds,
} from 'components/domain/filters/Filters.types';
import {
  getSearchControlValuesToFormStateMapper,
  mapSearchControlFormStateToTrackableData,
} from 'components/domain/searchControl/SearchControl.mappers';
import { ISearchControlFormState } from 'components/domain/searchControl/SearchControl.types';
import { SearchControlFragment } from 'graphql/searchControl/SearchControl.generated';
import { MainFilterComponentValuesFragment } from 'graphql/searchFilter/MainFilterComponentValues.generated';
import {
  GetSrlAnyQuery,
  SrlSingleResultFragment,
} from 'graphql/srl/GetSrlAny.generated';
import { GetSrlSearchValuesQuery } from 'graphql/srl/GetSrlSearchValues.generated';
import { ISRLState, SRLItemTypes } from './SRL.types';

export const mapSRLSearchControlValuesToFormState = getSearchControlValuesToFormStateMapper<
  SearchControlFragment | undefined,
  ISearchControlFormState
>((searchControl: SearchControlFragment | undefined) => {
  if (!searchControl) return null;

  return {
    travelType: searchControl.travelType,
    travelDates: mapSearchControlTravelPeriodToFormTravelDates(
      searchControl.period
    ),
    travelRooms: mapSearchControlTravelRoomsToFormTravelRooms(
      searchControl.travellers,
      searchControl.rooms,
      searchControl.travellersDistribution
    ),
    travelDestination: mapSearchControlTravelDestinationsToFormTravelDestination(
      searchControl.destinations
    ),
  };
});

const filtersFormStateKeyToSrlFilterCriteriaInputKey: {
  [K in keyof IFiltersFormState]: keyof SrlFilterCriteriaInput;
} = {
  boardTypes: 'boardTypes',
  accommodationSize: 'accommodationSize',
  departureAirports: 'departureAirports',
  flightStopOver: 'flightStopOver',
  hotelplanRating: 'hotelplanRating',
  maxFlightDuration: 'maxFlightDuration',
  maxPricePerPerson: 'maxPricePerPerson',
  roomTypes: 'roomTypes',
  tripAdvisorRating: 'tripAdvisorRating',
  departureWeekdays: 'departureWeekday',
  arrivalAirports: 'arrivalAirports',
  minPrice: 'minPricePerPerson',
  productCode: 'productCode',
  provider: 'provider',
  flightProvider: 'flightProvider',
  arrivalWeekdays: 'returnWeekday',
  directFlightDepartureTime: 'departureFlightDepartureTime',
  directFlightArrivalTime: 'departureFlightArrivalTime',
  flightAirlines: 'airlines',
  returnFlightArrivalTime: 'returnFlightArrivalTime',
  returnFlightDepartureTime: 'returnFlightDepartureTime',
  radius: 'radius',
};

export const mapSrlFilterValuesToSrlFilterCriteriaInput = (
  filterState: IFiltersFormState | null | undefined
): SrlFilterCriteriaInput =>
  mapFiltersStateToFilterCriteriaInput(
    filterState,
    filtersFormStateKeyToSrlFilterCriteriaInputKey
  );

export const mapSubGeoFiltersToFormState = (
  items: Array<{ id: string; selected: boolean }>
): string[] | null => {
  return items.map(({ id }) => id);
};

export const mapSRLSearchValuesQueryToSRLState = (
  srlQuery: GetSrlSearchValuesQuery
): ISRLState => {
  const {
    searchControls: { searchControl, mainFilter, subGeoFilter, sort },
  } = srlQuery.srl;

  return {
    searchControl: mapSRLSearchControlValuesToFormState(
      searchControl as SearchControlComponent
    ),
    filters: mapMainFilterComponentValuesToFiltersFormState(
      mainFilter as MainFilterComponentValuesFragment
    ),
    subGeoFilters: mapSubGeoFiltersToFormState(subGeoFilter.items ?? []),
    primarySort: sort.primarySort,
    productSorting: sort.productSorting,
    groupSorting: sort.groupSorting,
    forceSingleView: srlQuery.srl.searchControls.forceSingleView,
  };
};

export const mapSRLStateToTrackableData = (
  state: ISRLState
): IRunSearchData => {
  return {
    searchControl: mapSearchControlFormStateToTrackableData(
      state.searchControl
    ),
    filters: mapFiltersStateToTrackableData(state.filters),
  };
};

const mapSrlSingleItemsToNudgesAndProductsEventMetaData = (
  items: (SrlSingleItem | SrlGeoItem)[]
) => {
  const nudges: SRLNudgeEventMetaData[] = [];
  const products: SRLProductEventMetaData[] = [];

  items.forEach((item, i) => {
    if (item.__typename === 'NudgeItem') {
      nudges.push({ nudge: item.text, pos: i + 1 });
    }

    if (item.__typename === 'SrlProductItem') {
      const from = item.departureDate;
      const to = item.returnDate;
      const los = item.duration;

      let geo;
      item.geoBreadcrumbs.forEach(geoBr => {
        geo = {
          ...geo,
          [geoBr.type.toLowerCase()]: {
            id: geoBr.id,
            label: geoBr.name,
          },
        };
      });

      products.push({
        id: item.giata,
        price: item.price.amount,
        types: item.featureSummary.map(feature => feature.id),
        category: item.hpRating,
        disruptor: item.disrupter,
        date: {
          from,
          to,
          los,
        },
        rooms: item.rooms.map(room => ({
          board: room.boardType,
          room: room.roomType,
          bookingCode: room.bookingCode,
        })),
        source: item.provider,
        code: item.productCode,
        rating: item.taRating,
        geo,
      });
    }

    if (item.__typename === 'SrlGeoItem') {
      const geo = ({
        [item.geoObject.type.toLowerCase()]: {
          id: item.geoObject.id,
          label: item.geoObject.name,
        },
      } as unknown) as SRLGeoMetaData;

      products.push({
        price: item.perPersonPrice.amount,
        disruptor: item.disrupter,
        geo,
      });
    }
  });

  return { nudges, products };
};

export const mapSRLSingleResultsToTrackableSRLResultsEventMetaData = (
  singleData: SrlSingleResultFragment,
  state: ISRLState,
  queryTime = 0
): SRLResultsEventMetaData => {
  const type = 'products';
  const success = true;
  const price = singleData.cheapestProduct.price.amount;
  const perfomance = { time: queryTime };
  const {
    products,
    nudges,
  } = mapSrlSingleItemsToNudgesAndProductsEventMetaData(
    singleData.items as SrlSingleItem[]
  );
  const results = singleData.productsTotal;

  return {
    type,
    success,
    price,
    perfomance,
    products,
    nudges,
    results,
    sort: state.productSorting,
    page: singleData.page.pageNumber,
  };
};

export const mapSRLGroupResultsToTrackableSRLResultsEventMetaData = (
  groupData: SrlGeoGroupResult,
  state: ISRLState,
  queryTime = 0
): SRLResultsEventMetaData => {
  const type = 'groups';
  const success = true;
  const price = groupData.cheapestGroup.perPersonPrice.amount;
  const perfomance = { time: queryTime };
  const groups = groupData.groups.map(group => {
    return {
      geoObject: {
        id: group.geoObject.id,
        name: group.geoObject.name,
        type: group.geoObject.type as string,
      },
      ...mapSrlSingleItemsToNudgesAndProductsEventMetaData(
        group.items as SrlGeoItem[]
      ),
    };
  });
  const results = groupData.productsTotal;

  return {
    type,
    success,
    price,
    perfomance,
    groups,
    results,
    sort: state.groupSorting,
  };
};

export const mapSRLGroupResultsToTrackableSRLResultsEventMetaDataGroupPaging = (
  previousResult: SRLResultsEventMetaData,
  geoGroupItem: SrlGeoGroupItem
): SRLResultsEventMetaData => {
  if (previousResult.type === 'products') return;

  const {
    products,
    nudges,
  } = mapSrlSingleItemsToNudgesAndProductsEventMetaData(geoGroupItem.items);

  return {
    ...previousResult,
    groups: previousResult.groups.map(group =>
      group.geoObject.id === geoGroupItem.geoObject.id
        ? {
            geoObject: group.geoObject,
            nudges: [...group.nudges, ...nudges],
            products: [...group.products, ...products],
          }
        : group
    ),
  };
};

export const mapSrlGeoGroupItemToTrackableSRLResultsEventMetaData = (
  geoGroupItem: SrlGeoGroupItem,
  state: ISRLState,
  queryTime = 0
): SRLResultsEventMetaData => {
  const type = 'products';
  const success = true;

  const perfomance = { time: queryTime };
  const {
    products,
    nudges,
  } = mapSrlSingleItemsToNudgesAndProductsEventMetaData(geoGroupItem.items);
  const results = geoGroupItem.page.resultsTotal || 0;

  return {
    type,
    success,
    perfomance,
    products,
    nudges,
    results,
    sort: state.productSorting,
    page: geoGroupItem.page.pageNumber,
  };
};

export const mapSRLEmptyResultsToTrackableSRLResultsEventMetaData = (
  state: ISRLState,
  queryTime = 0
): SRLResultsEventMetaData => {
  const type = 'products';
  const success = false;
  const perfomance = { time: queryTime };
  const price = '';
  const results = 0;
  const sort = '' as SrlProductSorting;
  const products = [];

  return {
    type,
    success,
    perfomance,
    price,
    results,
    sort,
    products,
    page: 0,
  };
};

export const mapSRLAnyResultsToTrackableSRLResultsEventMetaData = (
  data: GetSrlAnyQuery,
  state: ISRLState,
  queryTime = 0
): SRLResultsEventMetaData => {
  if (data?.srl.search.any.__typename === 'SrlGeoGroupResult') {
    const groupData = data.srl.search.any;
    return mapSRLGroupResultsToTrackableSRLResultsEventMetaData(
      groupData as SrlGeoGroupResult,
      state,
      queryTime
    );
  }

  if (data?.srl.search.any.__typename === 'SrlSingleResult') {
    const singleData = data.srl.search.any;
    return mapSRLSingleResultsToTrackableSRLResultsEventMetaData(
      singleData as SrlSingleResult,
      state,
      queryTime
    );
  }

  if (data?.srl.search.any.__typename === 'SrlEmptyResult') {
    return mapSRLEmptyResultsToTrackableSRLResultsEventMetaData(
      state,
      queryTime
    );
  }

  return;
};

enum FieldType {
  CHECKBOX = 'checkbox',
  RADIO = 'radio',
}

type TPopularFiltersOptionsFields = {
  filterNames: Array<keyof IFiltersFormState> | keyof IFiltersFormState;
  fieldsType: FieldType;
};

type TPopularFiltersOptions = {
  [key in OptionsIds]: TPopularFiltersOptionsFields;
};

const popularFiltersOptions: TPopularFiltersOptions = {
  [OptionsIds.allInclusive]: {
    filterNames: [FilterOptions.boardTypes, FilterOptions.mainFeatures],
    fieldsType: FieldType.CHECKBOX,
  },
  [OptionsIds.halfBoard]: {
    filterNames: FilterOptions.boardTypes,
    fieldsType: FieldType.CHECKBOX,
  },
  [OptionsIds.sustainability]: {
    filterNames: FilterOptions.mainFeatures,
    fieldsType: FieldType.CHECKBOX,
  },
  [OptionsIds.beachHolidays]: {
    filterNames: FilterOptions.mainFeatures,
    fieldsType: FieldType.CHECKBOX,
  },
  [OptionsIds.cityHolidays]: {
    filterNames: FilterOptions.mainFeatures,
    fieldsType: FieldType.CHECKBOX,
  },
  [OptionsIds.ZRH]: {
    filterNames: FilterOptions.departureAirports,
    fieldsType: FieldType.CHECKBOX,
  },
  [OptionsIds.GVA]: {
    filterNames: FilterOptions.departureAirports,
    fieldsType: FieldType.CHECKBOX,
  },
  [OptionsIds.ZHR]: {
    filterNames: FilterOptions.departureAirports,
    fieldsType: FieldType.CHECKBOX,
  },
  [OptionsIds.HP_MIN_4_STARS]: {
    filterNames: FilterOptions.hotelplanRating,
    fieldsType: FieldType.RADIO,
  },
  [OptionsIds.DIRECT]: {
    filterNames: FilterOptions.flightStopOver,
    fieldsType: FieldType.RADIO,
  },
};

export function changeSRLFiltersWithPopularFiltersInterceptor(
  nextValues: IFiltersFormState,
  prevValues: IFiltersFormState
): IFiltersFormState {
  Object.keys(popularFiltersOptions).forEach(optionId => {
    const filterNames = popularFiltersOptions[optionId].filterNames;

    if (Array.isArray(filterNames)) {
      filterNames.forEach(filter => {
        const {
          inNextPopularFilter,
          inPrevPopularFilter,
          inPrevFilter,
          inNextFilter,
        } = getIsOptionIdIncludesInFilter({
          nextValues,
          filter,
          prevValues,
          optionId,
        });

        const inSecondNextFilter = nextValues[filterNames[1]]?.includes(
          optionId
        );

        mapFilterValuesToSynchronizeSameOptionsInDifferentFilters({
          inPrevPopularFilter,
          inNextPopularFilter,
          inNextFilter,
          inPrevFilter,
          filterNames: filter,
          optionId,
          nextValues,
        });

        if (
          !inPrevFilter &&
          inSecondNextFilter &&
          !inNextPopularFilter &&
          !inNextFilter
        ) {
          nextValues[filter] = [...(nextValues[filter] || []), optionId];
        }

        if (inPrevFilter && !inSecondNextFilter && inNextPopularFilter) {
          nextValues[filter] = nextValues[filter]?.filter(
            id => id !== optionId
          );
        }
      });
    } else {
      const {
        inNextPopularFilter,
        inPrevPopularFilter,
        inPrevFilter,
        inNextFilter,
      } = getIsOptionIdIncludesInFilter({
        nextValues,
        filter: filterNames,
        prevValues,
        optionId,
      });

      mapFilterValuesToSynchronizeSameOptionsInDifferentFilters({
        inPrevPopularFilter,
        inNextPopularFilter,
        inNextFilter,
        inPrevFilter,
        filterNames,
        optionId,
        nextValues,
      });
    }
  });

  return nextValues;
}

type TMapFiltersValues = {
  filter: string;
  optionId: string;
  nextValues: IFiltersFormState;
  prevValues: IFiltersFormState;
};

const mapFilterValuesToSynchronizeSameOptionsInDifferentFilters = ({
  inPrevPopularFilter,
  inNextPopularFilter,
  inNextFilter,
  nextValues,
  inPrevFilter,
  filterNames,
  optionId,
}: Partial<TMapFiltersValues> & {
  filterNames: string;
  inPrevPopularFilter: boolean;
  inPrevFilter: boolean;
  inNextPopularFilter: boolean;
  inNextFilter: boolean;
}) => {
  if (!inPrevPopularFilter && inNextPopularFilter && !inNextFilter) {
    nextValues[filterNames] =
      FieldType.CHECKBOX === popularFiltersOptions[optionId].fieldsType
        ? [...(nextValues[filterNames] || []), optionId]
        : optionId || '';
  }

  if (!inPrevFilter && inNextFilter && !inNextPopularFilter) {
    nextValues.popularFilters = [
      ...(nextValues.popularFilters || []),
      optionId,
    ];
  }

  if (inPrevPopularFilter && !inNextPopularFilter && inNextFilter) {
    FieldType.CHECKBOX === popularFiltersOptions[optionId].fieldsType
      ? (nextValues[filterNames] = nextValues[filterNames]?.filter(
          id => id !== optionId
        ))
      : (nextValues[filterNames] = '');
  }

  if (inPrevFilter && !inNextFilter && inNextPopularFilter) {
    nextValues.popularFilters = nextValues.popularFilters?.filter(
      id => id !== optionId
    );
  }

  if (
    inPrevPopularFilter !== inNextPopularFilter ||
    inNextFilter !== inPrevFilter
  ) {
    nextValues.prevChangedFilter = filterNames as TFilterOptionName;
  }
};

const getIsOptionIdIncludesInFilter = ({
  filter,
  optionId,
  nextValues,
  prevValues,
}: TMapFiltersValues): {
  inPrevPopularFilter: boolean;
  inPrevFilter: boolean;
  inNextPopularFilter: boolean;
  inNextFilter: boolean;
} => {
  return {
    inPrevPopularFilter: prevValues.popularFilters?.includes(optionId),
    inPrevFilter: prevValues[filter]?.includes(optionId),
    inNextPopularFilter: nextValues.popularFilters?.includes(optionId),
    inNextFilter: nextValues[filter]?.includes(optionId),
  };
};

export function changeSRLFiltersInterceptor(
  nextValues: IFiltersFormState,
  prevValues: IFiltersFormState
): IFiltersFormState {
  const inPrevBoard = prevValues.boardTypes?.includes(OptionsIds.allInclusive);
  const inPrevFeature = prevValues.mainFeatures?.includes(
    OptionsIds.allInclusive
  );
  const inNextBoard = nextValues.boardTypes?.includes(OptionsIds.allInclusive);
  const inNextFeature = nextValues.mainFeatures?.includes(
    OptionsIds.allInclusive
  );

  if (!inPrevBoard && inNextBoard && !inNextFeature) {
    nextValues.mainFeatures = [
      ...(nextValues.mainFeatures || []),
      OptionsIds.allInclusive,
    ];
  }

  if (!inPrevFeature && inNextFeature && !inNextBoard) {
    nextValues.boardTypes = [
      ...(nextValues.boardTypes || []),
      OptionsIds.allInclusive,
    ];
  }

  if (inPrevBoard && !inNextBoard && inNextFeature) {
    nextValues.mainFeatures = nextValues.mainFeatures?.filter(
      id => id !== OptionsIds.allInclusive
    );
  }

  if (inPrevFeature && !inNextFeature && inNextBoard) {
    nextValues.boardTypes = nextValues.boardTypes?.filter(
      id => id !== OptionsIds.allInclusive
    );
  }

  if (inPrevBoard !== inNextBoard || inNextFeature !== inPrevFeature) {
    nextValues.prevChangedFilter = FilterOptions.boardTypes;
  }

  return nextValues;
}

export const filterElementPlacementById = <A extends { id: string }>(
  data: A[],
  elementId: string,
  placement: number
): A[] => {
  if (!data) return [];
  if (data.length < placement) return data;

  const placementIndex = placement - 1;
  const elementIndex = data.findIndex(el => el.id === elementId);

  if (elementIndex === -1 || elementIndex === placementIndex) return data;
  const itemToMove = data[elementIndex];

  return data.flatMap((item, index) => {
    if (index === elementIndex) return [];
    if (index === placementIndex)
      return elementIndex < placementIndex
        ? [item, itemToMove]
        : [itemToMove, item];
    return item;
  });
};

export const calculateOfferPosition = (
  offers: SrlSingleItem[],
  index
): number => {
  const onlyProductsOffers = offers.filter(
    ({ __typename }, i) => i < index && __typename === SRLItemTypes.PRODUCT
  );
  return onlyProductsOffers.length + 1;
};
