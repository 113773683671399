import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
export type AirportFragmentFragment = {
  __typename?: 'Airport';
  iata?: string | null;
  name?: string | null;
};

export const AirportFragmentFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'airportFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Airport' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'iata' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode;
