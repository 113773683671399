import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
export type GeoFragmentFragment = {
  __typename?: 'GeoObject';
  id: string;
  name: string;
  type: Types.GeoType;
};

export const GeoFragmentFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'geoFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GeoObject' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode;
