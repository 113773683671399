import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
export type TravelPeriodFragment = {
  __typename?: 'TravelPeriodComponent';
  type: Types.SearchPeriodType;
  exactPeriod: {
    __typename?: 'ExactTravelPeriod';
    departureDate: string;
    returnDate: string;
  };
  flexiblePeriod: {
    __typename?: 'FlexibleTravelPeriod';
    departureDate: string;
    maxDuration: number;
    minDuration: number;
    returnDate: string;
  };
};

export const TravelPeriodFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TravelPeriod' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TravelPeriodComponent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'exactPeriod' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'departureDate' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'returnDate' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flexiblePeriod' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'departureDate' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'maxDuration' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minDuration' } },
                { kind: 'Field', name: { kind: 'Name', value: 'returnDate' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode;
